
import { defineComponent } from 'vue'
import { ROCK_DATA, TARGET_ROCK_COUNT } from '@/constants'
import Rock from '@/types/Rock'
import { POLISH_CYCLES } from '@/types/POLISH_CYCLES'
import { collectTrophies } from '@/lib'

export default defineComponent({
  name: 'Wash.vue',
  methods: {
    markWashed () {
      this.playing = false
    },
    flipWaterfall () {
      if (this.playing) {
        setTimeout(this.flipWaterfall, 100)
      }
      this.alternate = !this.alternate
    },
    washRocks () {
      this.playing = true
      this.$store.commit('setWashed', true)
      if (this.$store.state.cycle !== POLISH_CYCLES.POLISH) {
        this.$store.commit('setCanChangeGrit', true)
      }
      setTimeout(this.markWashed, 3000)
      setTimeout(this.flipWaterfall, 50)
    },
    rockData (rock: Rock) {
      return ROCK_DATA[rock.type].assets[this.currentStage]
    },
    moveToTrophy () {
      collectTrophies(this.$store)
      this.$router.push({ name: 'Trophy' })
    }
  },
  watch: {
    playing (value: boolean) {
      const audio = this.$refs.audio as HTMLAudioElement
      if (value) {
        audio.play()
      }
    }
  },
  computed: {
    washed () { return this.$store.state.washed },
    rocks () { return this.$store.state.rockLists.tumbling },
    currentStage () { return this.$store.state.cycle },
    finished () {
      return (
        (this.$store.state.cycle === POLISH_CYCLES.POLISH) &&
        this.$store.state.washed &&
        this.$store.state.cycleCompleted)
    }
  },
  created () {
    const state = this.$store.state
    if (state.running || state.washed) {
      // It's not time to wash.
      this.$router.replace({ name: 'Tumbler' })
    }
    if (state.rockLists.tumbling.length < TARGET_ROCK_COUNT) {
      this.$router.replace({ name: 'RockPicker' })
    }
  },
  data (): {playing: boolean, alternate: boolean} {
    return {
      playing: false,
      alternate: false
    }
  }
})
